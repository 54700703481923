import { createReducer } from '@reduxjs/toolkit';
import actions from './actions';
import Driver from '@/models/Driver';

export interface DriversState {
  drivers: Driver[];
  driverById: {
    [key: string]: Driver;
  };
  loading: boolean;
}

const initialState: DriversState = {
  drivers: [],
  driverById: {},
  loading: false,
};

export default createReducer<DriversState>(initialState, {
  [actions.SET_STATE]: (state, action) => ({ ...state, ...action.payload }),
});
