import { BaseModel } from 'sjs-base-model';
import Address from '@/models/Address';
import BankAccount from '@/models/BankAccaunt';
import Contact from '@/models/Contact';

type Status = 'check' | 'active' | 'inactive' | 'terminated';
export const customerStatuses = ['check' , 'active' , 'inactive' , 'terminated'] 

type CustomerType = 'customer' | 'broker' | 'participant' | 'carrier' | 'renter' | 'owner' | 'misc';
export const customerTypes = ['customer' , 'broker' , 'participant' , 'carrier' , 'renter' , 'owner' , 'misc']


type LegalEntityType = 'juridical_person' | 'natural_person';
export const customerLegalTypes = ['juridical_person','natural_person']

export default class Customer extends BaseModel {
  public attr: {
    created_by: string | null;
    updated_by: string | null;
    legal_entity_type: LegalEntityType;
  } = {
    created_by: null,
    updated_by: null,
    legal_entity_type: 'natural_person',
  };
  public company_id: string = '';
  public customer_id: string = '';
  public external_id: string = '';
  public status: Status = 'check';
  public created: string = '';
  public updated: string = '';
  public name: string = '';
  public fullname: string = '';
  public customer_type: CustomerType[] = ['broker'];
  public contacts: Contact[] = [Contact as any];
  public addresses: Address[] = [Address as any];
  public bank_accounts: BankAccount[] = [BankAccount as any];

  constructor(data: any) {
    super();
    this.update(data);
  }
}