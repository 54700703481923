import { reduce } from 'lodash';
import React, { useState, useEffect, FC } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { AppState } from '@/store/reducers';
import styles from './style.module.scss';
import { MenuChild } from '@/services/menu';

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux, RouteComponentProps{
}


const Breadcrumbs: FC<Props> = props => {
  const [breadcrumbs, setBreadcrumbs] = useState<any>([]);
  const {
    location: { pathname },
    menuData = [],
  } = props;

  useEffect(() => {
    setBreadcrumbs(() => getBreadcrumbs());
  }, [pathname, menuData]);

  const getPath = (data:MenuChild[], url:string, parents:any[] = []):any=> {
    const items:any = reduce(
      data,
      (result:any, entry:any) => {
        if (result.length) {
          return result;
        }
        if (entry.url === url) {
          return [entry].concat(parents);
        }
        if (entry.children) {
          const nested = getPath(entry.children, url, [entry].concat(parents));
          return (result || []).concat(nested.filter((e:any) => !!e));
        }
        return result;
      },
      [],
    );
    return items.length > 0 ? items : [false];
  };

  const getBreadcrumbs = () => {
    const [activeMenuItem, ...path] = getPath(menuData, pathname);

    if (!activeMenuItem) {
      return null;
    }
    if (activeMenuItem && path.length) {
      return path.reverse().map((item:any, index:number) => {
        if (index === path.length - 1) {
          return (
            <span key={item.key}>
              <span className={styles.arrow} />
              <span>{item.title}</span>
              <span className={styles.arrow} />
              <strong className={styles.current}>{activeMenuItem.title}</strong>
            </span>
          );
        }
        return (
          <span key={item.key}>
            <span className={styles.arrow} />
            <span>{item.title}</span>
          </span>
        );
      });
    }
    return [
      <span key="1">
        <span className={styles.arrow} />
        <strong className={styles.current}>{activeMenuItem.title}</strong>
      </span>,
    ];
  };
  return (
    breadcrumbs &&
    (breadcrumbs.length ? (
      <div className={styles.breadcrumbs}>
        <div className={styles.path}>
          <Link to="/">Home</Link>
          {breadcrumbs}
        </div>
      </div>
    ) : null)
  );
};

const mapStateToProps = ({ menu }: AppState) => ({
  menuData: menu.menuData,
});

const connector = connect(mapStateToProps)

export default withRouter(connector(Breadcrumbs));
