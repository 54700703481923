import { Tooltip } from 'antd';
import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../../store/reducers';

const mapStateToProps = ({ settings }: AppState) => ({
  theme: settings.theme,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {}

const ThemeSwitcher: FunctionComponent<Props> = ({ dispatch, theme }) => {
  const setTheme = (nextTheme: string) => {
    dispatch({
      type: 'settings/SET_THEME',
      payload: {
        theme: nextTheme,
      },
    });
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'menuColor',
        value: nextTheme === 'dark' ? 'dark' : 'light',
      },
    });
  };

  return (
    <div>
      <Tooltip title="Switch Dark / Light Theme" placement="left">
        <a
          role="button"
          onFocus={e => {
            e.preventDefault();
          }}
          onKeyPress={() => setTheme(theme === 'default' ? 'dark' : 'default')}
          onClick={() => setTheme(theme === 'default' ? 'dark' : 'default')}
          style={{ verticalAlign: 'middle' }}
        >
          {theme === 'default' && <i className="fe fe-moon" />}
          {theme !== 'default' && <i className="fe fe-sun" />}
        </a>
      </Tooltip>
    </div>
  );
};

const connector = connect(mapStateToProps);

export default connector(ThemeSwitcher);
