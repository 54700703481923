import { createReducer } from '@reduxjs/toolkit';
import store from 'store';
import actions from './actions';

const STORED_SETTINGS = (storedSettings: any) => {
  const settings: any = {};
  Object.keys(storedSettings).forEach(key => {
    const item = store.get(`app.settings.${key}`);
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key];
  });
  return settings;
};

export interface SettingsState {
  logo: string;
  locale: string;
  isSidebarOpen: boolean;
  isSupportChatOpen: boolean;
  isMobileView: boolean;
  isMobileMenuOpen: boolean;
  isMenuCollapsed: boolean;
  menuLayoutType: 'left' | 'top' | 'nomenu'; // left, top, nomenu
  routerAnimation: 'slide-fadein-up' | 'slide-fadein-right' | 'none' | 'fadein' | 'zoom-fadein'; // none, slide-fadein-up, slide-fadein-right, fadein, zoom-fadein
  menuColor: 'white' | 'dark' | 'gray'; // white, dark, gray
  theme: 'default' | 'dark'; // default, dark
  authPagesColor: 'white' | 'gray' | 'image'; // white, gray, image
  primaryColor: string;
  leftMenuWidth: number;
  isMenuUnfixed: boolean;
  isMenuShadow: boolean;
  isTopbarFixed: boolean;
  isGrayTopbar: boolean;
  isContentMaxWidth: boolean;
  isAppMaxWidth: boolean;
  isGrayBackground: boolean;
  isCardShadow: boolean;
  isSquaredBorders: boolean;
  isBorderless: boolean;
}

const initialState: SettingsState = {
  ...STORED_SETTINGS({
    logo: 'RMFR',
    locale: 'en-US',
    isSidebarOpen: false,
    isSupportChatOpen: false,
    isMobileView: false,
    isMobileMenuOpen: false,
    isMenuCollapsed: false,
    menuLayoutType: 'left', // left, top, nomenu
    routerAnimation: 'slide-fadein-up', // none, slide-fadein-up, slide-fadein-right, fadein, zoom-fadein
    menuColor: 'white', // white, dark, gray
    theme: 'default', // default, dark
    authPagesColor: 'white', // white, gray, image
    primaryColor: '#4b7cf3',
    leftMenuWidth: 256,
    isMenuUnfixed: false,
    isMenuShadow: false,
    isTopbarFixed: false,
    isGrayTopbar: false,
    isContentMaxWidth: false,
    isAppMaxWidth: false,
    isGrayBackground: false,
    isCardShadow: true,
    isSquaredBorders: false,
    isBorderless: false,
  }),
};

export default createReducer<SettingsState>(initialState, {
  [actions.SET_STATE]: (state, action) => ({ ...state, ...action.payload }),
});
