import { BaseModel } from 'sjs-base-model';

export default class Contact extends BaseModel {
  public name: string = '';
  public description: string|null = null;
  public email: string|null = null;
  public phone: string|null = null;
  public whatsapp: string|null = null;
  public telegram: string|null = null;


  constructor(data: any) {
    super();
    this.update(data);
  }
}