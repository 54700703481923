import { BaseModel } from 'sjs-base-model';

type Status = 'active' | 'inactive';
export const companyStatusTypes = ['active', 'inactive'];
type Ownership = 'owner_drivers' | 'owner_trailers' | 'owner_tractors' | 'owner_loads' | 'owner_dispatch';

export const companyOwnershipTypes = [
  'owner_drivers',
  'owner_trailers',
  'owner_tractors',
  'owner_loads',
  'owner_dispatch',
];

export default class Company extends BaseModel {
  public attr: {
    created_by: string | null;
    updated_by: string | null;
  } = {
    created_by: null,
    updated_by: null,
  };
  public company_id: string = '';
  public status: Status = 'active';
  public created: string = '';
  public updated: string = '';
  public lsn: number = 0;
  public serial: number = 0;
  public name: string = '';
  public ownership: Ownership[] = [];

  constructor(data: any) {
    super();
    this.update(data);
  }
}
