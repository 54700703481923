export const updateDataFromApi: <T>(option: {
  dataFromApi: T[];
  dataFromStore: T[];
  dataByIdFromStore: { [key: string]: T };
  key: keyof T;
}) => { data: T[]; dataById: { [key: string]: T } } = ({ dataFromApi, dataFromStore, dataByIdFromStore, key }) => {
  dataFromStore = [...dataFromStore];
  dataByIdFromStore = { ...dataByIdFromStore };
  dataFromApi.forEach(itemFromApi => {
    const idx = dataFromStore.findIndex(itemFromStore => itemFromStore[key] === itemFromApi[key]);
    dataByIdFromStore[itemFromApi[key] as any as string] = itemFromApi;
    if (idx === -1) {
      dataFromStore.push(itemFromApi);
    } else {
      dataFromStore[idx] = itemFromApi;
    }
  });
  return {
    data: dataFromStore,
    dataById: dataByIdFromStore,
  };
};
