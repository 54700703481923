import { BaseModel } from 'sjs-base-model';

type Kind = 'USA_CDL';

type Cdl = 'CDL-A' | 'CDL-B' | 'CDL-C';

export default class License extends BaseModel {
  public kind: Kind | null = null;
  public cdl_number: string | null = null;
  public cdl_type: Cdl | null = null;
  public issue_date: string | null = null;
  public expiration_date: string | null = null;

  constructor(data: any) {
    super();
    this.update(data);
  }
}
