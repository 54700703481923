export const filterIdsToLoad: <T>(option: { ids: string[]; dataById: (id: string) => T | undefined }) => string[] = ({
  dataById,
  ids,
}) => {
  const idsToLoad: string[] = [];
  // ищем в хранилище, если есть то ничего не грузим
  ids.forEach(id => {
    if (!dataById(id)) idsToLoad.push(id);
  });
  return idsToLoad;
};
