export interface Category {
  category: true;
  title: string;
}

// хз что такое   count и children -  пока оставим
export interface MenuItem {
  title: string;
  key: string;
  icon: string;
  url: string;
  permit?: string;

  count?: any;
  children?: any;
}

export type MenuChild = Category | MenuItem;

export default async function getMenuData(): Promise<MenuChild[]> {
  return [
    {
      category: true,
      title: 'rmfr',
    },
    {
      title: 'Users',
      key: 'users',
      icon: 'fe fe-users',
      url: '/users',
      permit: 'user_list',
    },
    {
      title: 'My roles',
      key: 'my_roles',
      icon: 'fe fe-airplay',
      url: '/my-roles',
      permit: 'access_list',
    },
    {
      title: 'Companies',
      key: 'companies',
      icon: 'fe fe-briefcase',
      url: '/companies',
      permit: 'company_list',
    },
    {
      title: 'Partners',
      key: 'customers',
      icon: 'fe fe-database',
      url: '/customers',
      permit: 'customer_list',
    },
    {
      title: 'Drivers',
      key: 'drivers',
      icon: 'fe fe-play',
      url: '/drivers',
      permit: 'driver_list',
    },
  ];
}
