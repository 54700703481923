import { AppState } from '@/store/reducers';

export const customerById = (state: AppState) => (customer_id: string|null) => {
  if (!customer_id) return undefined;
  return state.customers.customerById[customer_id];
};

export const customers = (state: AppState) =>  {
  return state.customers.customers
};

export const customersById = (state: AppState) =>  {
  return state.customers.customerById
};
