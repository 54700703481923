import { ConnectedRouter } from 'connected-react-router';
import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Layout from './layouts';

const routes = [
  {
    path: '/login-done',
    Component: lazy(() => import('./pages/login/login-done')),
    exact: true,
  },
  {
    path: '/login',
    Component: lazy(() => import('./pages/login/login')),
    exact: true,
  },
  {
    path: '/users',
    Component: lazy(() => import('./pages/users/index')),
    exact: true,
  },
  {
    path: '/companies',
    Component: lazy(() => import('./pages/companies/index')),
    exact: true,
  },
  {
    path: '/customers',
    Component: lazy(() => import('./pages/customers/index')),
    exact: true,
  },
  {
    path: '/drivers',
    Component: lazy(() => import('./pages/drivers/index')),
    exact: true,
  },
  {
    path: '/my-roles',
    Component: lazy(() => import('./pages/my-roles/index')),
    exact: true,
  },
  {
    path: '/404',
    Component: lazy(() => import('./pages/404/index')),
    exact: true,
  },
  {
    path: '/',
    Component: lazy(() => import('./pages/main/index')),
    exact: true,
  },
];

const mapStateToProps = ({ settings, user }: any) => ({
  routerAnimation: settings.routerAnimation,
  authorized: user.authorized,
});

const Router = ({ history, routerAnimation, authorized }: any) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state;
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {authorized ? <Redirect from="/login" to='/' />  : ''}
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          );
                        }}
                      />
                    ))}
                    <Redirect to='/404' />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            );
          }}
        />
      </Layout>
    </ConnectedRouter>
  );
};

export default connect(mapStateToProps)(Router);
