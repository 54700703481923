import { AxiosResponse } from 'axios';
import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import Driver from '@/models/Driver';
import { filterIdsToLoad } from '@/store/utils/filterIdsToLoad';
import { updateDataFromApi } from '@/store/utils/updateDataFromApi';
import { DriversLoadResponse } from '@/types/responses';
import api from '../../api';
import actions from './actions';
import { DriversSelectors } from './index';

export function* LOAD_DRIVERS_BY_ID({
  payload: { needReload, driver_ids },
}: {
  type: typeof actions.LOAD_DRIVERS_BY_ID;
  payload: { needReload: boolean; driver_ids: string[] };
}) {
  const driverById: ReturnType<typeof DriversSelectors.driverById> = yield select(DriversSelectors.driverById);
  const idsToLoad = needReload ? driver_ids : filterIdsToLoad({ ids: driver_ids, dataById: driverById });
  if (idsToLoad.length === 0) {
    return;
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  const response: AxiosResponse<DriversLoadResponse> = yield call(api.drivers.load, { driver_ids: idsToLoad });
  if (response?.data?.drivers) {
    const driversFromApi = response.data.drivers.map(c => new Driver(c));
    const driversFromStore: ReturnType<typeof DriversSelectors.drivers> = yield select(DriversSelectors.drivers);
    const driversFromStoreById: ReturnType<typeof DriversSelectors.driversById> = yield select(
      DriversSelectors.driversById,
    );

    const { data, dataById } = updateDataFromApi({
      dataFromApi: driversFromApi,
      dataFromStore: driversFromStore,
      dataByIdFromStore: driversFromStoreById,
      key: 'driver_id',
    });

    yield put({
      type: actions.SET_STATE,
      payload: {
        drivers: data,
        driverById: dataById,
        loading: false,
      },
    });
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
  }
}

export function* ADD_DRIVERS_TO_STORE({
  payload: { drivers },
}: {
  type: typeof actions.ADD_DRIVERS_TO_STORE;
  payload: { drivers: Driver[] };
}) {
  const driversFromApi = drivers;
  const driversFromStore: ReturnType<typeof DriversSelectors.drivers> = yield select(DriversSelectors.drivers);
  const driversFromStoreById: ReturnType<typeof DriversSelectors.driversById> = yield select(
    DriversSelectors.driversById,
  );

  const { data, dataById } = updateDataFromApi({
    dataFromApi: driversFromApi,
    dataFromStore: driversFromStore,
    dataByIdFromStore: driversFromStoreById,
    key: 'driver_id',
  });

  yield put({
    type: actions.SET_STATE,
    payload: {
      drivers: data,
      driverById: dataById,
    },
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_DRIVERS_BY_ID, LOAD_DRIVERS_BY_ID),
    takeEvery(actions.ADD_DRIVERS_TO_STORE, ADD_DRIVERS_TO_STORE),
  ]);
}
