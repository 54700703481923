import { BaseModel } from 'sjs-base-model';

interface Attr {
  updated_by: string | null;
  created_by: string | null;
}

type Provider = 'guest' | 'google';
type Role = 'guest' | 'superadmin';


type Status = 'active' | 'inactive';
export const userStatusTypes = ['active', 'inactive'];

export default class User extends BaseModel {
  public attr: Attr = {
    updated_by: null,
    created_by: null,
  };
  public company_id: string | null = null;
  public created: string = '';
  public device: string[] = [];
  public email: string | null = null;
  public fullname: string | null = null;
  public is_developer: boolean = false;
  public lsn: null;
  public nick: string | null = null;
  public phone: string | null = null;
  public provider: Provider = 'guest';
  public provider_user_id: 'guest' = 'guest';
  public role: Role = 'guest';
  public serial: string | null = null;
  public status: Status = 'active';
  public updated: string = '';
  public user_id: string  = '';
  public vars: {} = {};

  constructor(data: any) {
    super();
    this.update(data);
  }
}
