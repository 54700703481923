import { SectionConfig } from '@/constants/formFields/types';
import User from '@/models/User';

export const meFields: SectionConfig<User>[] = [
  {
    label: 'Main',
    fields: [
      {
        key: 'nick',
        label: 'Nickname',
        type: 'string',
        inputType: 'text',
        canEdit: true,
      },
      {
        key: 'phone',
        label: 'Phone',
        type: 'string',
        inputType: 'text',
        canEdit: true,
      },
    ],
  },
];
