import { all } from 'redux-saga/effects';
import companies from './companies/sagas';
import customers from './customers/sagas';
import drivers from './drivers/sagas';
import menu from './menu/sagas';
import settings from './settings/sagas';
import user from './user/sagas';
import users from './users/sagas';

export default function* rootSaga() {
  yield all([user(), menu(), settings(), users(), companies(), customers(), drivers()]);
}
