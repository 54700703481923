import { AppState } from '@/store/reducers';

export const permitByName = (state:AppState) => (name: string) => {
  const permit = state.user.permits.find(p => p.name === name);
  if (permit) {
    return permit.value;
  }
  return false;
};

export const role = (state:AppState)=> {
  return state.user.user.role
};
