import { AxiosResponse } from 'axios';
import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import Customer from '@/models/Customer';
import { filterIdsToLoad } from '@/store/utils/filterIdsToLoad';
import { updateDataFromApi } from '@/store/utils/updateDataFromApi';
import { CustomersLoadResponse } from '@/types/responses';
import api from '../../api';
import actions from './actions';
import { CustomersSelectors } from './index';

export function* LOAD_CUSTOMERS_BY_ID({
  payload: { needReload, customer_ids },
}: {
  type: typeof actions.LOAD_CUSTOMERS_BY_ID;
  payload: { needReload: boolean; customer_ids: string[] };
}) {
  const customerById: ReturnType<typeof CustomersSelectors.customerById> = yield select(
    CustomersSelectors.customerById,
  );
  const idsToLoad = needReload ? customer_ids : filterIdsToLoad({ ids: customer_ids, dataById: customerById });
  if (idsToLoad.length === 0) {
    return;
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  });
  const response: AxiosResponse<CustomersLoadResponse> = yield call(api.customers.load, { customer_id: idsToLoad });
  if (response?.data?.customers) {
    const customersFromApi = response.data.customers.map(c => new Customer(c));
    const customersFromStore: ReturnType<typeof CustomersSelectors.customers> = yield select(
      CustomersSelectors.customers,
    );
    const customersFromStoreById: ReturnType<typeof CustomersSelectors.customersById> = yield select(
      CustomersSelectors.customersById,
    );

    const { data, dataById } = updateDataFromApi({
      dataFromApi: customersFromApi,
      dataFromStore: customersFromStore,
      dataByIdFromStore: customersFromStoreById,
      key: 'customer_id',
    });

    yield put({
      type: actions.SET_STATE,
      payload: {
        customers: data,
        customerById: dataById,
        loading: false,
      },
    });
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    });
  }
}

export function* ADD_CUSTOMERS_TO_STORE({
  payload: { customers },
}: {
  type: typeof actions.ADD_CUSTOMERS_TO_STORE;
  payload: { customers: Customer[] };
}) {
  const customersFromApi = customers;
  const customersFromStore: ReturnType<typeof CustomersSelectors.customers> = yield select(
    CustomersSelectors.customers,
  );
  const customersFromStoreById: ReturnType<typeof CustomersSelectors.customersById> = yield select(
    CustomersSelectors.customersById,
  );

  const { data, dataById } = updateDataFromApi({
    dataFromApi: customersFromApi,
    dataFromStore: customersFromStore,
    dataByIdFromStore: customersFromStoreById,
    key: 'customer_id',
  });

  yield put({
    type: actions.SET_STATE,
    payload: {
      customers: data,
      customerById: dataById,
    },
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_CUSTOMERS_BY_ID, LOAD_CUSTOMERS_BY_ID),
    takeEvery(actions.ADD_CUSTOMERS_TO_STORE, ADD_CUSTOMERS_TO_STORE),
  ]);
}
