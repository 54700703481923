import 'antd/lib/style/index.less'; // antd core styles
import './style/antd/default.less'; // default theme antd components
import './style/antd/dark.less'; // dark theme antd components
import './style/global.scss'; // app & third-party component styles
import ReactDOM from 'react-dom';
import React from 'react';
import sagas from '@/store/sagas';

import { Provider } from 'react-redux';
import Localization from './localization';
import Router from './router';
import {store,history,sagaMiddleware} from '@/store/reducers'


sagaMiddleware.run(sagas);

ReactDOM.render(
  <Provider store={store}>
    <Localization>
      <Router history={history} />
    </Localization>
  </Provider>,
  document.getElementById('root'),
);
