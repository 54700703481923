import { createReducer } from '@reduxjs/toolkit';
import Company from '@/models/Company';
import actions from './actions';

export interface CompaniesState {
  companies: Company[];
  companyById: {
    [key: string]: Company;
  };
  loading: boolean;
}

const initialState: CompaniesState = {
  companies: [],
  companyById: {},
  loading: false,
};

export default createReducer<CompaniesState>(initialState, {
  [actions.SET_STATE]: (state, action) => ({ ...state, ...action.payload }),
});
