import { BaseModel } from 'sjs-base-model';
import Address from '@/models/Address';
import BankAccount from '@/models/BankAccaunt';
import Contact from '@/models/Contact';
import License from '@/models/License';

type Status = 'candidate' | 'check' | 'reject' | 'active' | 'inactive' | 'terminated';
export const driverStatusTypes = ['candidate', 'check', 'reject', 'active', 'inactive', 'terminated'];

type WorkStatus = 'suspend' | 'free' | 'work' | 'vacation' | 'day_off' | 'disease';
export const workStatusTypes = ['suspend', 'free', 'work', 'vacation', 'day_off', 'disease'];

export default class Driver extends BaseModel {
  public attr: {
    created_by: string | null;
    updated_by: string | null;
  } = {
    created_by: null,
    updated_by: null,
  };
  public external_id: string = '';
  public driver_id: string = '';
  public customer_id: string | null = '';
  public company_id: string = '';
  public first_name: string = '';
  public middle_name: string | null = null;
  public last_name: string = '';
  public license: License | null = License as any;
  public status: Status | null = 'candidate';
  public work_status: WorkStatus = 'suspend';
  public contacts: Contact[] = [Contact as any];
  public addresses: Address[] = [Address as any];
  public bank_accounts: BankAccount[] = [BankAccount as any];
  public created: string = '';
  public updated: string = '';

  constructor(data: any) {
    super();
    this.update(data);
  }

  get fullName():string {
    if (this.middle_name) {
      return `${this.first_name} ${this.middle_name} ${this.last_name}`;
    }
    return `${this.first_name} ${this.last_name}`;
  }
}
