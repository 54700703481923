import { BaseModel } from 'sjs-base-model';

type CountryCode = 'US'

export default class Address extends BaseModel {
  public country_code: CountryCode = 'US';
  public state: string = '';
  public city: string = '';
  public street: string = '';
  public postal_code: string = '';
  public lon: number|null = null;
  public lat: number|null = null;

  constructor(data: any) {
    super();
    this.update(data);
  }
}