import { AppState } from '@/store/reducers';

export const companyById = (state: AppState) => (company_id: string | null) => {
  if (!company_id) return undefined;
  return state.companies.companyById[company_id];
};

export const companies = (state: AppState) => {
  return state.companies.companies;
};

export const companiesById = (state: AppState) => {
  return state.companies.companyById;
};

export const myCompany = (state: AppState) => {
  return companyById(state)(state.user.user.company_id);
};
