import { createReducer } from '@reduxjs/toolkit';
import { MenuChild } from '@/services/menu';
import actions from './actions';

export interface MenuState {
  menuData: MenuChild[];
}

const initialState: MenuState = {
  menuData: [],
};

export default createReducer<MenuState>(initialState, {
  [actions.SET_STATE]: (state, action) => ({ ...state, ...action.payload }),
});
