import axios, { AxiosResponse } from 'axios';
import Status from '@/models/Status';
import {
  AccessCreateRequest,
  AccessesListRequest,
  AccessUpdateRequest,
  CompaniesListRequest,
  CompaniesLoadRequest,
  CompanyCreateRequest,
  CompanyUpdateRequest,
  CustomerCreateRequest,
  CustomersListRequest,
  CustomersLoadRequest,
  CustomerUpdateRequest,
  DriverCreateRequest,
  DriversListRequest,
  DriversLoadRequest,
  DriverUpdateRequest,
  UserCreateRequest,
  UserJoinCompanyRequest,
  UsersListRequest,
  UsersLoadRequest, UserSwitchRoleRequest, UserUpdateMeRequest,
  UserUpdateRequest,
} from '@/types/requests';
import {
  AccessCreateResponse,
  AccessesListResponse,
  AccessUpdateResponse,
  CompaniesListResponse,
  CompaniesLoadResponse,
  CompanyCreateResponse,
  CompanyUpdateResponse,
  CustomerCreateResponse,
  CustomersListResponse,
  CustomersLoadResponse,
  CustomerUpdateResponse,
  DriverCreateResponse,
  DriversListResponse,
  DriversLoadResponse,
  DriverUpdateResponse,
  OptionResponse,
  UserCreateResponse,
  UserJoinCompanyResponse,
  UsersListResponse,
  UsersLoadResponse, UserSwitchRoleResponse,
  UserUpdateResponse,
} from '@/types/responses';
import { Load } from './store/loads/Load';
import { getTokens } from './utils/tokensStorage';

export const baseURL = 'https://mt-api.rmfr.ru/api/';
axios.defaults.baseURL = baseURL;

axios.interceptors.response.use(undefined, error => {
  return new Promise((resolve, reject) => {
    // ждем рефреш токенов
    // if (error?.response?.status === 401 && !error?.config?.__isRetryRequest) {
    //   const { refresh } = getTokens();
    //   if (refresh) {
    //     api.token
    //       .refresh({ refresh })
    //       .then(({ data }) => {
    //         writeTokens(data);
    //         error.config.__isRetryRequest = true;
    //         error.config.headers.Authorization = `Bearer ${data.access}`;
    //         axios(error.config).then(resolve, reject);
    //       })
    //       .catch(() => reject(error));
    //   }
    // }
    reject(error);
  });
});

axios.interceptors.request.use(request => {
  request.headers = {
    'Content-Type': 'application/json',
  };

  const { access } = getTokens();
  if (access) {
    request.headers.Authorization = `Bearer ${access}`;
  }
  return request;
});

export interface CreateStatusPayload {
  comment: string;
  driver: number;
  // id водителя
  from_dttm: string;
  status: 'TEMPORARY_OFF';
  till_dttm: string;
}

export interface EditStatusPayload extends CreateStatusPayload {
  id: number;
}

export type Provider = 'yandex' | 'google' | 'test';

const api = {
  loads: {
    loads_list: ({
      page = 1,
      size = 20,
      expand = [],
      period = undefined,
    }: {
      page: number;
      size: number;
      expand: string[];
      period?: string;
    }): Promise<
      AxiosResponse<{
        count: number;
        next?: number;
        pageCount: number;
        previous?: number;
        results: Load[];
      }>
    > => {
      const expandString = expand.join(',');
      return axios.get('loads/', {
        params: { page, size, expand: expandString, period },
      });
    },
  },
  driver_statuses: {
    create: (payload: CreateStatusPayload): Promise<AxiosResponse<Status>> => {
      return axios.post('driver_statuses/', payload);
    },
    edit: (payload: EditStatusPayload): Promise<AxiosResponse<Status>> => {
      return axios.put(`driver_statuses/${payload.id}`, payload);
    },
    delete: (id: number): Promise<AxiosResponse<Status>> => {
      return axios.delete(`driver_statuses/${id}`);
    },
  },
  // new api
  profile: {
    authPrepare: (provider: Provider) => {
      return axios.post('profile/auth-prepare', { provider });
    },
    authDone: ({ state, code }: { state: string; code: string }) => {
      return axios.post('profile/auth-done', { state, code });
    },
    options: () => {
      return axios.get<OptionResponse>('profile/options');
    },
  },
  users: {
    list: (option: UsersListRequest) => {
      return axios.post<UsersListResponse>('users/list', option);
    },
    load: (option: UsersLoadRequest) => {
      return axios.post<UsersLoadResponse>('users/load', option);
    },
    create: (option: UserCreateRequest) => {
      return axios.post<UserCreateResponse>('users/create', option);
    },
    update: (option: UserUpdateRequest) => {
      return axios.post<UserUpdateResponse>('users/update', option);
    },
    updateMe: (option: UserUpdateMeRequest) => {
      return axios.post<UserUpdateResponse>('users/update', option);
    },
    join_company: (option: UserJoinCompanyRequest) => {
      return axios.post<UserJoinCompanyResponse>('users/join_company', option);
    },
    switch_role: (option: UserSwitchRoleRequest) => {
      return axios.post<UserSwitchRoleResponse>('users/switch_role', option);
    },
  },
  accesses: {
    list: (option: AccessesListRequest) => {
      return axios.post<AccessesListResponse>('accesses/list', option);
    },
    create: (option: AccessCreateRequest) => {
      return axios.post<AccessCreateResponse>('accesses/create', option);
    },
    update: (option: AccessUpdateRequest) => {
      return axios.post<AccessUpdateResponse>('accesses/update', option);
    },
  },
  companies: {
    list: (option: CompaniesListRequest) => {
      return axios.post<CompaniesListResponse>('companies/list', option);
    },
    load: (option: CompaniesLoadRequest) => {
      if (option.company_ids.length === 0) throw new Error('invalid param');
      return axios.post<CompaniesLoadResponse>('companies/load', option);
    },
    create: (option: CompanyCreateRequest) => {
      return axios.post<CompanyCreateResponse>('companies/create', option);
    },
    update: (option: CompanyUpdateRequest) => {
      return axios.post<CompanyUpdateResponse>('companies/update', option);
    },
  },
  customers: {
    list: (option: CustomersListRequest) => {
      return axios.post<CustomersListResponse>('customers/list', option);
    },
    load: (option: CustomersLoadRequest) => {
      return axios.post<CustomersLoadResponse>('customers/load', option);
    },
    create: (option: CustomerCreateRequest) => {
      return axios.post<CustomerCreateResponse>('customers/create', option);
    },
    update: (option: CustomerUpdateRequest) => {
      return axios.post<CustomerUpdateResponse>('customers/update', option);
    },
  },
  drivers: {
    list: (option: DriversListRequest) => {
      return axios.post<DriversListResponse>('drivers/list', option);
    },
    load: (option: DriversLoadRequest) => {
      return axios.post<DriversLoadResponse>('drivers/load', option);
    },
    create: (option: DriverCreateRequest) => {
      return axios.post<DriverCreateResponse>('drivers/create', option);
    },
    update: (option: DriverUpdateRequest) => {
      return axios.post<DriverUpdateResponse>('drivers/update', option);
    },
  },
};

export default api;
