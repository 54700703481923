import React from 'react'
import FavPages from './FavPages'
import Search from './Search'
import LanguageSwitcher from './LanguageSwitcher'
import UserMenu from './UserMenu'
import style from './style.module.scss'
import ThemeSwitcher from './ThemeSwitcher'

const TopBar = () => {
  return (
    <div className={style.topbar}>
      <div className="me-4">
        {/* <FavPages /> */}
      </div>
      <div className="me-auto">
        {/* <Search /> */}
      </div>
      <div className="me-4">
        <ThemeSwitcher />
      </div>
      <div className="me-4 d-none d-sm-block">
        <LanguageSwitcher />
      </div>
      <div className="">
        <UserMenu />
      </div> 
    </div>
  )
}

export default TopBar
