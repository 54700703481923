import { UserOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Avatar, Badge, notification } from 'antd';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import api from '@/api';
import FormModal from '@/components/Modal';
import { meFields } from '@/constants/formFields/me';
import { getLabelFromKey } from '@/constants/formFields/utils';
import { useModal } from '@/hooks/useModal';
import User from '@/models/User';
import { AppState } from '@/store/reducers';
import styles from './style.module.scss';

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {}

const ProfileMenu: FC<Props> = ({ dispatch, user }) => {
  const { modalMode, setModalMode } = useModal<User>();

  const logout = (e: any) => {
    e.preventDefault();
    dispatch({
      type: 'user/LOGOUT',
    });
  };

  const saveUser = async ({ phone, nick }: Partial<User>) => {
    try {
      await api.users.updateMe({
        user_id: user.user_id,
        phone,
        nick,
      });
      user.update({
        phone: phone || user.phone,
        nick: nick || user.nick,
      });
      notification.success({
        message: 'Updated',
        description: 'You have successfully update user!',
      });
      setModalMode(false);
    } catch (e) {
      notification.error({
        message: 'Error',
        description: 'Error!',
      });
      console.error(e);
    }
  };

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <strong>
          <FormattedMessage id="topBar.profileMenu.hello" />, {user.fullname || 'Anonymous'}
        </strong>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.role" />:{' '}
          </strong>
          {getLabelFromKey(user.role) || '—'}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.email" />:{' '}
          </strong>
          {user.email || '—'}
          <br />
          <strong>
            <FormattedMessage id="topBar.profileMenu.phone" />:{' '}
          </strong>
          {user.phone || '—'}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Divider />
      <Menu.Item>
        <button className={styles.userButton} type="button" onClick={() => setModalMode('edit')}>
          <i className="fe fe-edit me-2" />
          Edit
        </button>
      </Menu.Item>
      <Menu.Item>
        <button className={styles.userButton} type="button" onClick={logout}>
          <i className="fe fe-log-out me-2" />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </button>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      {modalMode && (
        <FormModal<User>
          handleClose={() => {
            setModalMode(false);
          }}
          handleSave={saveUser}
          data={user}
          mode={modalMode}
          fields={meFields}
          createTitle={data => data?.fullname || ''}
        />
      )}
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          <Badge>
            <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
          </Badge>
        </div>
      </Dropdown>
    </>
  );
};

const mapStateToProps = ({ user }: AppState) => ({ user: user.user });

const connector = connect(mapStateToProps);

export default connector(ProfileMenu);
