import { createReducer } from '@reduxjs/toolkit';
import actions from './actions';
import Customer from '@/models/Customer';

export interface CustomersState {
  customers: Customer[];
  customerById: {
    [key: string]: Customer;
  };
  loading: boolean;
}

const initialState: CustomersState = {
  customers: [],
  customerById: {},
  loading: false,
};

export default createReducer<CustomersState>(initialState, {
  [actions.SET_STATE]: (state, action) => ({ ...state, ...action.payload }),
});
