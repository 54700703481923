import { AxiosResponse } from 'axios';
import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import User from '@/models/User';
import { store } from '@/store/reducers';
import { filterIdsToLoad } from '@/store/utils/filterIdsToLoad';
import { updateDataFromApi } from '@/store/utils/updateDataFromApi';
import { UsersListRequest } from '@/types/requests';
import { UsersListResponse } from '@/types/responses';
import { UsersSelectors } from '.';
import api from '../../api';
import actions from './actions';

export function* LOAD_USERS_BY_IDS({
  payload: { user_ids, needReload },
}: {
  type: typeof actions.LOAD_USERS_BY_IDS;
  payload: { user_ids: string[]; needReload: boolean };
}) {
  const userById: ReturnType<typeof UsersSelectors.userById> = yield select(UsersSelectors.userById);
  const idsToLoad = needReload ? user_ids : filterIdsToLoad({ ids: user_ids, dataById: userById });
  if (idsToLoad.length === 0) {
    return;
  }
  yield put({
    type: 'users/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response: AxiosResponse<UsersListResponse> = yield call(api.users.load, { user_id: idsToLoad });
  if (response?.data?.users) {
    const usersFromApi = response.data.users.map(u => new User(u));
    const usersFromStore: ReturnType<typeof UsersSelectors.users> = yield select(UsersSelectors.users);
    const usersFromStoreById: ReturnType<typeof UsersSelectors.usersById> = yield select(UsersSelectors.usersById);

    const { data, dataById } = updateDataFromApi({
      dataFromApi: usersFromApi,
      dataFromStore: usersFromStore,
      dataByIdFromStore: usersFromStoreById,
      key: 'user_id',
    });

    yield put({
      type: 'users/SET_STATE',
      payload: {
        users: data,
        userById: dataById,
        loading: false,
      },
    });
  } else {
    yield put({
      type: 'users/SET_STATE',
      payload: {
        loading: false,
      },
    });
  }
}

export function* ADD_USERS_TO_STORE({
  payload: { users },
}: {
  type: typeof actions.ADD_USERS_TO_STORE;
  payload: { users: User[] };
}) {
  const usersFromApi = users;
  const usersFromStore: ReturnType<typeof UsersSelectors.users> = yield select(UsersSelectors.users);
  const usersFromStoreById: ReturnType<typeof UsersSelectors.usersById> = yield select(UsersSelectors.usersById);

  const { data, dataById } = updateDataFromApi({
    dataFromApi: usersFromApi,
    dataFromStore: usersFromStore,
    dataByIdFromStore: usersFromStoreById,
    key: 'user_id',
  });

  yield put({
    type: 'users/SET_STATE',
    payload: {
      users: data,
      userById: dataById,
    },
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_USERS_BY_IDS, LOAD_USERS_BY_IDS),
    takeEvery(actions.ADD_USERS_TO_STORE, ADD_USERS_TO_STORE),
  ]);
}
