import { AxiosResponse } from 'axios';
import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import Company from '@/models/Company';
import { filterIdsToLoad } from '@/store/utils/filterIdsToLoad';
import { updateDataFromApi } from '@/store/utils/updateDataFromApi';
import { CompaniesLoadResponse } from '@/types/responses';
import api from '../../api';
import actions from './actions';
import { CompanySelectors } from './index';

export function* LOAD_COMPANIES_BY_ID({
  payload: { needReload, company_ids },
}: {
  type: typeof actions.LOAD_COMPANIES_BY_ID;
  payload: { needReload: boolean; company_ids: string[] };
}) {
  const companyById: ReturnType<typeof CompanySelectors.companyById> = yield select(CompanySelectors.companyById);
  const idsToLoad = needReload ? company_ids : filterIdsToLoad({ ids: company_ids, dataById: companyById });
  if (idsToLoad.length === 0) {
    return;
  }
  yield put({
    type: 'companies/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const response: AxiosResponse<CompaniesLoadResponse> = yield call(api.companies.load, { company_ids: idsToLoad });
  if (response?.data?.companies) {
    const companiesFromApi = response.data.companies.map(c => new Company(c));
    const companiesFromStore: ReturnType<typeof CompanySelectors.companies> = yield select(CompanySelectors.companies);
    const companyiesFromStoreById: ReturnType<typeof CompanySelectors.companiesById> = yield select(
      CompanySelectors.companiesById,
    );

    const { data, dataById } = updateDataFromApi({
      dataFromApi: companiesFromApi,
      dataFromStore: companiesFromStore,
      dataByIdFromStore: companyiesFromStoreById,
      key: 'company_id',
    });

    yield put({
      type: 'companies/SET_STATE',
      payload: {
        companies: data,
        companyById: dataById,
        loading: false,
      },
    });
  } else {
    yield put({
      type: 'companies/SET_STATE',
      payload: {
        loading: false,
      },
    });
  }
}

export function* ADD_COMPANIES_TO_STORE({
  payload: { companies },
}: {
  type: typeof actions.ADD_COMPANIES_TO_STORE;
  payload: { companies: Company[] };
}) {
  const companiesFromApi = companies;
  const companiesFromStore: ReturnType<typeof CompanySelectors.companies> = yield select(CompanySelectors.companies);
  const companiesFromStoreById: ReturnType<typeof CompanySelectors.companiesById> = yield select(
    CompanySelectors.companiesById,
  );

  const { data, dataById } = updateDataFromApi({
    dataFromApi: companiesFromApi,
    dataFromStore: companiesFromStore,
    dataByIdFromStore: companiesFromStoreById,
    key: 'company_id',
  });

  yield put({
    type: actions.SET_STATE,
    payload: {
      companies: data,
      companyById: dataById,
    },
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_COMPANIES_BY_ID, LOAD_COMPANIES_BY_ID),
    takeEvery(actions.ADD_COMPANIES_TO_STORE, ADD_COMPANIES_TO_STORE),
  ]);
}
