interface Tokens {
    access: string;
    // refresh: string;
}
export function writeTokens(tokens: Tokens) {
    localStorage.setItem('rmfr-tokens', JSON.stringify(tokens))
}

export function clearTokens() {
    localStorage.removeItem('rmfr-tokens');
}

export function getTokens() {
    const tokens = localStorage.getItem('rmfr-tokens');
    if (tokens) {
        return JSON.parse(tokens)
    }

    return { access: '', refresh: '' }

}