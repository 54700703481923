import { AppState } from '@/store/reducers';

export const userById = (state: AppState) => (user_id: string) => {
  return state.users.userById[user_id];
};

export const users = (state: AppState) =>  {
  return state.users.users
};

export const usersById = (state: AppState) =>  {
  return state.users.userById
};
