import { createReducer } from '@reduxjs/toolkit';
import { Constants } from '@/types/constants';
import { Permits } from '@/types/permits';
import User from '../../models/User';
import actions from './actions';

export interface UserState {
  user: User;
  permits: Permits;
  constants: Constants;
  authorized: boolean;
  loading: boolean;
}

const initialState: UserState = {
  user: new User({}),
  permits: [],
  constants: {},
  authorized: Boolean(process.env.REACT_APP_AUTHENTICATED) || false, // false is default value
  loading: false,
};

export default createReducer<UserState>(initialState, {
  [actions.SET_STATE]: (state, action) => ({ ...state, ...action.payload }),
});
