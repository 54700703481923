import { createReducer } from '@reduxjs/toolkit';
import User from '../../models/User';
import actions from './actions';

export interface UsersState {
  users: User[];
  userById: {
    [key: string]: User;
  };
  loading: boolean;
}

const initialState: UsersState = {
  users: [],
  loading: false,
  userById: {},
};

export default createReducer<UsersState>(initialState, {
  [actions.SET_STATE]: (state, action) => ({ ...state, ...action.payload }),
});
