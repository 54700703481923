import { AppState } from '@/store/reducers';

export const driverById = (state: AppState) => (driver_id: string) => {
  return state.drivers.driverById[driver_id];
};

export const drivers = (state: AppState) => {
  return state.drivers.drivers;
};

export const driversById = (state: AppState) => {
  return state.drivers.driverById;
};
