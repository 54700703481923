import { notification } from 'antd';
import { AxiosResponse } from 'axios';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import User from '@/models/User';
import { history } from '@/store/reducers';
import { OptionResponse } from '@/types/responses';
import api, { Provider } from '../../api';
import { writeTokens, clearTokens } from '../../utils/tokensStorage';
import companyActions from '../companies/actions';
import actions from './actions';

export function* LOGIN({ payload }: { type: typeof actions.LOGIN; payload: Provider }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response: AxiosResponse<{ url: string }> = yield call(api.profile.authPrepare, payload);
  if (response?.data?.url) {
    yield window.location.assign(response.data.url);
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    });
  }
}

export function* AUTH_DONE({ payload }: { type: typeof actions.LOGIN; payload: { token: string } }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
      authorized: true,
    },
  });
  writeTokens({ access: payload.token });
  yield put({
    type: 'user/LOAD_CURRENT_ACCOUNT',
  });
  yield history.push('/');
  notification.success({
    message: 'Logged In',
    description: 'You have successfully logged in!',
  });
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const response: AxiosResponse<OptionResponse> = yield call(api.profile.options);
  if (response?.data?.user?.role !== 'guest') {
    const user = new User(response.data.user);
    const { permits, constants } = response.data;
    yield put({
      type: 'user/SET_STATE',
      payload: {
        user,
        permits,
        constants,
        loading: false,
        authorized: true,
      },
    });
    if (user.company_id) {
      yield put({
        type: companyActions.LOAD_COMPANIES_BY_ID,
        payload: {
          company_ids: [user.company_id],
        },
      });
    }
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    });
  }
}

export function* LOGOUT() {
  clearTokens();
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.AUTH_DONE, AUTH_DONE),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ]);
}
