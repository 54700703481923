import { Modal, Button, Form, Input } from 'antd';
import React, { PropsWithChildren, useState } from 'react';
import SelectWithLoadOptions from '@/components/Fields/Select';
import SelectWithSearch from '@/components/Fields/Select/selectWithSearch';
import { Field, SectionConfig } from '@/constants/formFields/types';
import style from './modal-style.module.scss';

type OwnProps<T extends object> = {
  handleClose: () => void;
  handleSave: (data: Partial<T>) => Promise<void>;
  data?: T;
  createTitle: (data?: T) => string;
  fields: SectionConfig<T>[];
  mode: 'edit' | 'view';
};

const FormModal: <T extends object>(props: PropsWithChildren<OwnProps<T>>) => JSX.Element = props => {
  const { handleClose, handleSave, data, createTitle, fields, mode } = props;
  const [isEdit, setEdit] = useState(mode === 'edit');
  const [loading, setLoading] = useState(false);

  type Data = Required<typeof props>['data'];

  const onFinish = async (values: Partial<Data>) => {
    try {
      setLoading(true);
      await handleSave(values);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const getFooterBtns = () => {
    if (isEdit) {
      return false;
    }
    return [
      <Button key="cancel" onClick={handleClose}>
        Cancel
      </Button>,
      <Button key="edit" type="primary" onClick={() => setEdit(true)}>
        Edit
      </Button>,
    ];
  };

  const getFieldFromConfig = (field: Field<Data>) => {
    switch (field.inputType) {
      case 'text':
        return (
          <Form.Item key={field.key as string} label={field.label} name={field.dataIndex || (field.key as string)}>
            <Input readOnly={!field.canEdit} />
          </Form.Item>
        );
      case 'select':
        return (
          <Form.Item key={field.key as string} label={field.label} name={field.dataIndex || (field.key as string)}>
            <SelectWithLoadOptions disabled={!field.canEdit} getOptions={field.getOptions} mode={field.mode} />
          </Form.Item>
        );
      case 'selectWithSearch':
        return (
          <Form.Item key={field.key as string} label={field.label} name={field.dataIndex || (field.key as string)}>
            <SelectWithSearch disabled={!field.canEdit} fetchOptions={field.getOptions} mode={field.mode} />
          </Form.Item>
        );
      default:
        return null;
    }
  };

  const getViewFromConfig = (field: Field<Data>) => {
    if (!data) return undefined;
    if (field.type === 'contacts') {
      return 'contact';
    }
    return (
      <div key={field.key as string} className={style.row}>
        <div className={style.el}>
          <span className={style.label}> {field.label}</span>
          <span className={style.value}>
            {'getHumanValue' in field && field.getHumanValue
              ? field.getHumanValue(data) || '-'
              : data[field.key] || '-'}
          </span>
        </div>
      </div>
    );
  };
  return (
    <Modal title={createTitle(data)} visible onCancel={handleClose} footer={getFooterBtns()}>
      {isEdit || !data ? (
        <Form
          layout="vertical"
          initialValues={data}
          onValuesChange={() => {}}
          onFinish={onFinish}
          className={style.editForm}
        >
          {fields.map(section => {
            return (
              <div key={section.label}>
                <span className={style.sectionLabel}>{section.label}</span>
                {section.fields.map(getFieldFromConfig)}
              </div>
            );
          })}

          <Form.Item style={{ marginBottom: 0 }} wrapperCol={{ offset: 8, span: 16 }}>
            <Button style={{ marginRight: 8 }} key="cancel" htmlType="button" onClick={handleClose}>
              Cancel
            </Button>
            <Button key="edit" type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <div className={style.modalContainer}>
          {fields.map(section => {
            return (
              <div key={section.label}>
                <span className={style.sectionLabel}>{section.label}</span>
                {section.fields.map(getViewFromConfig)}
              </div>
            );
          })}
        </div>
      )}
    </Modal>
  );
};

export default FormModal;
