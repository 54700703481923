import { configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import companies from './companies/reducer';
import customers from './customers/reducer';
import menu from './menu/reducer';
import settings from './settings/reducer';
import user from './user/reducer';
import users from './users/reducer';
import drivers from './drivers/reducer';

export const history = createBrowserHistory();
export const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);

export const store = configureStore({
  reducer: {
    router: connectRouter(history),
    user,
    users,
    menu,
    settings,
    companies,
    customers,
    drivers,
  },
  middleware: [sagaMiddleware, routeMiddleware],
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
