import { Menu, Layout } from 'antd';
import classNames from 'classnames';
import { find } from 'lodash';
import React, { useState, useEffect, FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import store from 'store';
import { CompanySelectors } from '@/store/companies';
import { AppState } from '@/store/reducers';
import { UserSelectors } from '@/store/user';
import style from './style.module.scss';
import Company from '@/models/Company';
import { myCompany } from '@/store/companies/selectors';

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux, RouteComponentProps {}

const MenuLeft: FC<Props> = ({
  dispatch,
  menuData,
  location: { pathname },
  isMenuCollapsed,
  isMobileView,
  isMenuUnfixed,
  isMenuShadow,
  leftMenuWidth,
  menuColor,
  logo,
}) => {
  const permitByName = useSelector<AppState, ReturnType<typeof UserSelectors.permitByName>>(state =>
    UserSelectors.permitByName(state),
  );
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || []);
  const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || []);

  const company = useSelector<AppState,Company|undefined>(state => CompanySelectors.myCompany(state));

  const loogoText = company ? company.name : logo;

  useEffect(() => {
    applySelectedKeys();
  }, [pathname, menuData]);

  const applySelectedKeys = () => {
    const flattenItems = (items: any, key: any) =>
      items.reduce((flattenedItems: any, item: any) => {
        flattenedItems.push(item);
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key));
        }
        return flattenedItems;
      }, []);
    const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname]);
    setSelectedKeys(selectedItem ? [selectedItem.key] : []);
  };

  const onCollapse = (value: any, type: any) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return;
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    });
    setOpenedKeys([]);
  };

  const onOpenChange = (keys: any) => {
    store.set('app.menu.openedKeys', keys);
    setOpenedKeys(keys);
  };

  const handleClick = (e: any) => {
    store.set('app.menu.selectedKeys', [e.key]);
    setSelectedKeys([e.key]);
  };

  const generateMenuItems = () => {
    const generateItem = (item: any) => {
      const { key, title, url, icon, disabled, count } = item;
      if (item.category) {
        return <Menu.ItemGroup key={Math.random()} title={item.title} />;
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target && (
              <a href={url} target={item.target} rel="noopener noreferrer">
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
                {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
              </a>
            )}
            {!item.target && (
              <Link to={url}>
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
                {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
              </Link>
            )}
          </Menu.Item>
        );
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          <span className={style.title}>{title}</span>
          {count && <span className="badge badge-success ml-2">{count}</span>}
          {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
        </Menu.Item>
      );
    };

    const generateSubmenu = (items: any) =>
      items.map((menuItem: any) => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={style.title}>{menuItem.title}</span>
              {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
              {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
            </span>
          );
          return (
            <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </Menu.SubMenu>
          );
        }
        return generateItem(menuItem);
      });

    return menuData.map(menuItem => {
      if ('permit' in menuItem && menuItem.permit && !permitByName(menuItem.permit)) {
        return null;
      }
      if ('children' in menuItem && menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={style.title}>{menuItem.title}</span>
            {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
            {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
          </span>
        );
        return (
          <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        );
      }
      return generateItem(menuItem);
    });
  };

  const menuSettings = isMobileView
    ? {
        width: leftMenuWidth,
        collapsible: false,
        collapsed: false,
        onCollapse,
      }
    : {
        width: leftMenuWidth,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse,
        breakpoint: 'lg' as 'lg',
      };

  return (
    <Layout.Sider
      width={menuSettings.width}
      collapsible={menuSettings.collapsible}
      collapsed={menuSettings.collapsed}
      onCollapse={menuSettings.onCollapse}
      breakpoint={menuSettings.breakpoint}
      className={classNames(`${style.menu}`, {
        [style.white]: menuColor === 'white',
        [style.gray]: menuColor === 'gray',
        [style.dark]: menuColor === 'dark',
        [style.unfixed]: isMenuUnfixed,
        [style.shadow]: isMenuShadow,
      })}
    >
      <div
        className={style.menuOuter}
        style={{
          width: isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth,
          height: isMobileView || isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)',
        }}
      >
        <div className={style.logoContainer}>
          <div className={style.logo}>
            <img src="resources/images/logo.svg" className="me-2" alt="Clean UI" />
            <div className={style.name}>{loogoText}</div>
          </div>
        </div>
        <PerfectScrollbar>
          <Menu
            onClick={handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={onOpenChange}
            mode="inline"
            className={style.navigation}
            inlineIndent={15}
          >
            {generateMenuItems()}
          </Menu>
        </PerfectScrollbar>
      </div>
    </Layout.Sider>
  );
};

const mapStateToProps = ({ menu, settings, user }: AppState) => ({
  menuData: menu.menuData,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isMenuUnfixed: settings.isMenuUnfixed,
  isMenuShadow: settings.isMenuShadow,
  leftMenuWidth: settings.leftMenuWidth,
  menuColor: settings.menuColor,
  logo: settings.logo,
  role: user.user.role,
});

const connector = connect(mapStateToProps);

export default withRouter(connector(MenuLeft));
