import { BaseModel } from 'sjs-base-model';

type CountryCode = 'US'

export default class BankAccount extends BaseModel {
  public country_code: CountryCode = 'US';
  public name: string = '';
  public description: string | null = null;
  public ach_routing_number: number = 0;
  public wire_routing_number: number|null = null;
  public account_number: number = 0;

  constructor(data: any) {
    super();
    this.update(data);
  }
}