import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/es/select';
import React, { useEffect } from 'react';
import { Option } from '@/constants/formFields/types';

export interface DebounceSelectProps<ValueType = any> extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  getOptions: () => Promise<ValueType[]>;
}

const SelectWithLoadOptions = ({ getOptions, ...props }: DebounceSelectProps) => {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState<Option[]>([]);
  const fetchRef = React.useRef(0);

  const debounceFetcher = React.useMemo(() => {
    return () => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      getOptions().then(newOptions => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };
  }, [getOptions]);

  useEffect(() => {
    debounceFetcher();
  }, []);

  return (
    <Select
      filterOption={false}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
};

export default SelectWithLoadOptions;
